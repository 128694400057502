import React from 'react';
import { Box, Typography, Table, TableBody,TableHead, TableCell, TableRow, Grid, Card, CardContent, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import studyLibraryImage from './study_library.jpg'; // Replace with the actual path to the image
import RevampNavBar from './RevampNavBar';

// Styled component for the background image
const BackgroundImageSection = styled(Box)({
    backgroundImage: `linear-gradient(#0F1214, transparent), url(${studyLibraryImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: 'white',
    padding: '40px 20px',
    position: 'relative',
    '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 74, 173, 0)', // Dark overlay for better readability
    },
});

const SQE1 = () => {
    return (
        <Box sx={{ bgcolor: '#0F1214', color: 'white', minHeight: '100vh', }}>

            <BackgroundImageSection>
            {/* Header and Description */}
            <Typography variant="h3" component="h1" textAlign="center" gutterBottom sx={{fontWeight: 'bold'}}>
                SQE1
            </Typography>
            <Typography variant="h6" textAlign="center" sx={{ mb: 4, ml:3, mr:3 }}>
                The SQE exam comprises of two assessments called functioning legal knowledge assessments (the FLK 1 and the FLK 2) which will assess you on various practical legal concepts and topics.
            </Typography>

            {/* Table Section */}
            <Box sx={{ bgcolor: 'white', color: 'black', borderRadius: '20px', p: 3, mb: 6, maxWidth: 600, mx: 'auto',  }}>
            <Table sx={{textAlign:'center'}}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}>FLK 1</TableCell>
                            <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}>FLK 2</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody align='center'>
                        <TableRow>
                            <TableCell align='center'>Business Law</TableCell>
                            <TableCell align='center'>Property Law</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align='center'>Business Finance</TableCell>
                            <TableCell align='center'>Wills and Administration of Estates</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align='center'>Dispute Resolution</TableCell>
                            <TableCell align='center'>Inheritance Tax</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align='center'>Constitutional Law</TableCell>
                            <TableCell align='center'>Trusts Law</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align='center'>Legal Services</TableCell>
                            <TableCell align='center'>Land Law</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align='center'>The Legal System of E+W</TableCell>
                            <TableCell align='center'>Criminal Law</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align='center'>Human Rights Act</TableCell>
                            <TableCell align='center'>Criminal Practice</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align='center'>Retained EU Law</TableCell>
                            <TableCell align='center'>Solicitors Accounts</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align='center'>Ethics</TableCell>
                            <TableCell align='center'>Ethics</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align='center'>Contract Law</TableCell>
                            <TableCell align='center'></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align='center'>Tort Law</TableCell>
                            <TableCell align='center'></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Box>

            {/* Packages Section */}
            
                <Box sx={{ position: 'relative', zIndex: 1, textAlign: 'center', mb: 4 }}>
                    <Typography variant="h4" component="h2">
                        Our Packages
                    </Typography>
                </Box>
                <Grid container spacing={3} justifyContent="center" sx={{ position: 'relative', zIndex: 1 }}>
  {/* Silver Package */}
  <Grid item xs={12}>
    <Card
      sx={{
        backgroundImage: 'linear-gradient(to bottom right, #B4B4B4, #FFFFFF, #B4B4B4, #B4B4B4)',
        borderRadius: '20px',
        textAlign: 'center',
        p: 3,
        color: 'black',
      }}
    >
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Silver Package - £35 per month
        </Typography>
        <em>(Best Value)</em>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Access to textbooks for all units. <br />
          Access to over 500 multiple-choice questions.
        </Typography>
        <Button variant="contained" color="primary" sx={{ borderRadius: '20px' }} href="https://buy.stripe.com/8wMdRnbbb9369wY8wM">
          Buy Now
        </Button>
      </CardContent>
    </Card>
  </Grid>

  {/* Bronze Practice Package */}
  <Grid item xs={12} md={6}>
    <Card
      sx={{
        backgroundImage: 'linear-gradient(to bottom right, #CF603D, #F2BA7A, #CF603D, #CF603D)',
        borderRadius: '20px',
        textAlign: 'center',
        p: 3,
        color: 'black',
      }}
    >
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Bronze Practice Package - £25 per month
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Access to over 500 multiple-choice questions.
        </Typography>
        <Button variant="contained" color="primary" sx={{ borderRadius: '20px' }} href="https://buy.stripe.com/9AQdRn2EF4MQ4cE9B0">
          Buy Now
        </Button>
      </CardContent>
    </Card>
  </Grid>

  {/* Bronze Revise Package */}
  <Grid item xs={12} md={6}>
    <Card
      sx={{
        backgroundImage: 'linear-gradient(to bottom right, #CF603D, #F2BA7A, #CF603D, #CF603D)',
        borderRadius: '20px',
        textAlign: 'center',
        p: 3,
        color: 'black',
      }}
    >
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Bronze Revise Package - £25 per month
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Access to all textbooks for all units.
        </Typography>
        <Button variant="contained" color="primary" sx={{ borderRadius: '20px' }} href="https://buy.stripe.com/eVa8x3gvvdjmfVm7sH">
          Buy Now
        </Button>
      </CardContent>
    </Card>
  </Grid>
</Grid>

                {/* Alternative Purchase Text */}
                <Box sx={{ textAlign: 'center', mt: 4, color: 'white', position: 'relative', zIndex: 1 }}>
                    <Typography variant="h6">
                        Alternatively, you are able to purchase material via a one-off purchase with a free account.
                    </Typography>
                </Box>
            </BackgroundImageSection>
        </Box>
    );
};

export default SQE1;
